










































































import { getStatusIcon } from "@/services/helpers-status";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Comment, IconsType, User } from "@/types";
import { namespace } from "vuex-class";
const UserModule = namespace("UserModule");
const ApplicationModule = namespace("ApplicationModule");
@Component({
  components: {
    CardTimelineNew: () => import("@/components/Card/CardTimelineNew.vue"),
    LabelStatus: () => import("@/components/Label/LabelStatus.vue")
  }
})
export default class CardHistory extends Vue {
  //
  // PROPS
  @Prop() readonly status!: string;
  //
  // COMPUTED
  @UserModule.Getter
  getRole!: string;
  @UserModule.Getter
  getName!: string;
  @ApplicationModule.State
  operations!: string[];
  @ApplicationModule.State
  history!: Comment[];
  get historyForDisplay() {
    if (this.getRole === "user") {
      return this.history.filter(({ statusAfter }) => statusAfter !== "paid");
    } else return this.history;
  }
  //
  // DATA
  icons: IconsType = {};
  operation = "";
  //
  // METHODS
  toLocaleTime(time: string) {
    return new Date(time).toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    });
  }
  statusIcon(status: string): string {
    return getStatusIcon(status);
  }
  name(author: User) {
    return `${author.familyName} ${author.firstName}`;
  }
  onUpdatedStatus(payload: string) {
    this.operation = payload;
  }
}
