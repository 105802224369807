import {
  mdiCancel,
  mdiCheck,
  mdiCurrencyRub,
  mdiEyeOutline,
  mdiPencil,
  mdiUndoVariant
} from "@mdi/js";
export function getStatusIcon(status: string): string {
  switch (status) {
    case "pending":
      return mdiEyeOutline;
    case "returned":
      return mdiUndoVariant;
    case "edited":
      return mdiPencil;
    case "approved":
      return mdiCheck;
    case "rejected":
      return mdiCancel;
    case "cancelled":
      return mdiCancel;
    case "paid":
      return mdiCurrencyRub;
    default:
      return "";
  }
}
export function getStatusColor(status: string): string {
  switch (status) {
    case "pending":
      return "secondary";
    case "returned":
      return "info";
    case "approved":
      return "success";
    case "rejected":
      return "error";
    case "cancelled":
      return "error";
    case "paid":
      return "success";
    default:
      return "secondary";
  }
}
